const Datapoint = {};

Datapoint.document = (id, version) => {
    return new Promise((resolve, reject) => {
        $socket.emit("functionals:Datapoint:document", { id, version }, ({ document, error }) => {
            resolve(document);
        });
    });
};

Datapoint.query = (id) => {
    return new Promise((resolve, reject) => {
        $socket.emit("functionals:Datapoint:query", { id }, ({ document, error }) => {
            resolve(document);
        });
    });
};

export default Datapoint;