<template>
    <v-container v-if="props.grid" fluid class="pa-0">
        <v-row no-gutters>
            <v-col v-for="(o, key, i) in format" :key="i">
                <v-sheet :min-width="props.elementMinWidth || 200">
                    <div class="ml-2">
                        <vuetiform-component :key="i" :format="o" :modelValue="object[key].value" @update:modelValue="(v) => atUpdate(key, v)" :disabled="props.disabled" /></div
                ></v-sheet>
            </v-col>
        </v-row>
    </v-container>
    <div v-else v-for="(o, key, i) in format">
        <vuetiform-component :key="i" :format="o" :modelValue="data.object[key].value" @update:modelValue="(...args) => atUpdate(key, ...args)" :disabled="props.disabled" />
    </div>
</template>

<script setup>
// for now, only the template differs

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
//import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw, computed } from "vue";
/*function clone(p) {
    return structuredClone(toRaw(p));
}*/

const props = defineProps(["format", "modelValue", "disabled", "elementMinWidth", "grid"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({ object: {} });

function generate(format = {}, modelValue = {}) {
    const object = {};
    for (const x in format) {
        const o = data.object[x] || {
            valid: true,
            ref: null,
        };
        object[x] = Object.assign(o, {
            format: format[x],
            value: modelValue[x],
        });
    }
    return object;
}

data.object = generate(props.format, props.modelValue);

function getRef(key) {
    return (el) => (data.object[key].ref = el);
}

async function refresh() {
    refreshComponents();
}

async function refreshComponents() {
    await nextTick();
    for (const key in data.object) if (data.object[key].ref) if (data.object[key].ref.refresh) await data.object[key].refresh();
}

defineExpose({ refresh });

function isValid() {
    for (const key in data.object) if (data.object[key].valid !== true) return key + ": " + data.object[key].valid;
    return true;
}

function atUpdate(key, value, nexus = { valid: true, change: false }) {
    data.object[key].value = value;
    data.object[key].valid = nexus.valid;
    updateHandler(null, nexus);
}

function updateHandler(value = null, nexus = { valid: true, change: false }) {
    const datum = {};
    for (const key in data.object) datum[key] = data.object[key].value;
    emit("update:modelValue", datum, { valid: isValid(), change: nexus.change });
}

onMounted(async () => {});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-object",
};
</script>
