function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

function isFunctionKey(key) { // "setFieldValue",
    if (["getTextValue", "getDefaultValue", "getComponentValue"].includes(key)) return true;
    if (key.includes(":")) return true;
    return false;
}

import getFunction from "@/vuetiform/getFunction.mjs";
export default function formatFunctions(format = {}) {

    const traverse = (input) => {
        if (!isObject(input)) return input;
        const functionkeys = [];
        const datafield = Object.entries(input).reduce((acc, [key, value]) => {
            if (isFunctionKey(key) && typeof value !== "function") {
                // value is the code representation in array of strings format
                const executeFunction = getFunction.call(this, value);
                acc[key] = executeFunction;
                acc[key + "-function"] = value;
                functionkeys.push(key);
              	
            } else if (isObject(value)) {
                acc[key] = traverse(value); // Recursively call traverse for nested objects
            } else {
                acc[key] = value; // Copy value directly for non-object and non-functionKey cases
            }
            return acc;
        }, {});

      
      	// we need a seperate run-up to set the default context of functions to the current datafield.
        functionkeys.forEach((key) => {
          	const executeFunction = datafield[key];
          	// by default bind the datafield to the function
          	// datafield[key] = datafield[key].bind({ Datafield: () => datafield });
            datafield[key] = datafield[key].bind({ __from_formatFunctions: true, datafield: () => datafield });
          	// and allow to recreate the function with a local/extended context. Example: @database-modules/dataview/vue3/database-components/CollectionTable.vue getFieldText()
          	datafield[key].executeFunction = executeFunction;
        });

        return datafield;
    };
    return traverse(format);
}

// ****/
// DocumentView 150
// CollectionView 100
