const Eventhandler = {};

Eventhandler.function = (id, init) => {
    return new Promise((resolve, reject) => {
        $socket.emit("functionals:Eventhandler:function", { id, init }, ({ document, error }) => {
            resolve(document);
        });
    });
};

export default Eventhandler;
