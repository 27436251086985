<template>
    <v-container class="codebox">
        <pre class="ma-1">{{ props.pretext }}</pre>
        <vue-codemirror :modelValue="data.modelValue" @update:modelValue="updateHandler" :extensions="extensions" />
        <v-icon @click="dialog = true">mdi-plus-circle</v-icon>

        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card class="pb-10">
                <v-card-actions>
                    <span class="text-h5 pa-6">##&en Insert code ##&hu Code beszúrás ##</span>
                    <v-spacer />
                    <v-btn size="large" @click="dialog = false" icon="mdi-close" />
                </v-card-actions>
                <v-card-text>
                    <div
                        v-for="(o, k, i) in props.autocompletion"
                        @mouseover="hover = i"
                        @mouseleave="hover = null"
                        :class="{ selected: hover === i }"
                        @click="
                            drop(o.drop || o.label || k);
                            dialog = false;
                        "
                        class="ma-1"
                    >
                        <pre class="pointer">{{ o.example || o.label || k }}</pre>
                    </div>
                </v-card-text>
                <!--v-card-actions>
                    <app-button icon="mdi-close" label="##&en Cancel ##&hu Mégse ##" @click="dialog = false" />
                    <v-spacer></v-spacer>
                    <app-button icon="mdi-check" label="OK" @click="clickEnter()" />
                </v-card-actions-->
            </v-card>
        </v-dialog>
        <v-chip v-for="(chip, i) in getContextItems()" @click="drop(chip)" class="ma-1"> {{ chip }} </v-chip>
        <pre class="ma-1">{{ data.valid === true ? lang || 'Javascript OK' : "ERROR " + data.valid }}</pre>
    </v-container>
</template>

<script setup>
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
import { parse } from "acorn";
import { simple } from "acorn-walk";

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "modelValue", "pretext", "contextkeys", "autocompletion", "lang"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({ modelValue: props.modelValue, valid: true, error: null });
let dialog = ref(false);
let hover = ref(-1);
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

function isJs() {
    if (!props.lang || props.lang === "javascript") return true;
    return false;
}

function isMath() {
    return props.lang === "mathjs";
}

import { create, all } from "mathjs";
const math = create(all, {});

function refresh() {
    Ł("??? VuetiformCodemirror refresh");
    Object.assign(data, { modelValue: props.modelValue || "" });
    Object.assign(data, { valid: isValid() });
}

defineExpose({ refresh });

function containsReturnStatement(ast) {
    let hasReturn = false;

    simple(ast, {
        ReturnStatement(node) {
            hasReturn = true;
        },
    });

    return hasReturn;
}

function isValid() {
    if (isJs())
        try {
            const parsed = parse(data.modelValue || "", { ecmaVersion: 2022, sourceType: "module", allowReturnOutsideFunction: true });
            if (!containsReturnStatement(parsed)) return "##&en Missing return statement ##&hu Nincs return parancs ##";
            return true;
        } catch (e) {
            return e.message;
        }
    if (isMath())
        try {
            math.compile(data.modelValue || "");
            return true;
        } catch (e) {
            return e.message;
        }

    return true;
}

function updateHandler(datum) {
    data.modelValue = datum;
    const valid = isValid();
    data.valid = valid;
    emit("update:modelValue", datum, { valid });
}

onMounted(async () => {
    data.valid = isValid();
});

// context related
function drop(chip) {
    if (data.modelValue.length > 0) updateHandler(data.modelValue + " " + chip);
    else updateHandler(chip);
}

function getContextItems() {
    return props.contextkeys || [];
}
function getFunctionalItems() {
    return props.functionals || [];
}
/// codemirror setup
import {
    keymap,
    highlightSpecialChars,
    drawSelection,
    highlightActiveLine,
    dropCursor,
    rectangularSelection,
    crosshairCursor,
    lineNumbers,
    highlightActiveLineGutter,
} from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { defaultHighlightStyle, syntaxHighlighting, indentOnInput, bracketMatching, foldGutter, foldKeymap } from "@codemirror/language";
import { defaultKeymap, history, historyKeymap } from "@codemirror/commands";
import { searchKeymap, highlightSelectionMatches } from "@codemirror/search";
import { autocompletion, completionKeymap, closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { lintKeymap } from "@codemirror/lint";

function myCompletions(context) {
    let word = context.matchBefore(/\w*/);
    if (word.from == word.to && !context.explicit) {
        return null; // Only show completions when explicitly requested
    }
    const o = props.autocompletion || {};
    return {
        from: word.from,
        options: Object.keys(o).map((key) => ({ label: key, type: "variable", ...o[key] })),
    };
}

const basicSetup = (() => [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    history(),
    //foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    //closeBrackets(),
    autocompletion({ override: [myCompletions] }),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    keymap.of([...closeBracketsKeymap, ...defaultKeymap, ...searchKeymap, ...historyKeymap, ...foldKeymap, ...completionKeymap, ...lintKeymap]),
])();

//-------------
const extensions = [keymap.of(defaultKeymap), basicSetup];

//import { linter, lintGutter } from "@codemirror/lint";
import { javascript } from "@codemirror/lang-javascript";
if (isJs()) extensions.push(javascript());
//extensions.push(lintGutter());

//import { lintKeymap } from "@codemirror/lint";
//import { esLint } from "@codemirror/lang-javascript";
//import { tags as t } from "@lezer/highlight";

//import { oneDark } from "@codemirror/theme-one-dark";
//extensions.push(oneDark);

import { createTheme } from "thememirror";
import { cobalt } from "thememirror";
extensions.push(cobalt);

import { EditorView } from "@codemirror/view";
let highlightTheme = EditorView.theme(
    {
        ".cm-selectionMatch": {
            backgroundColor: "#FFF5",
        },
    },
    { dark: true },
);
const customSelectionStyle = EditorView.baseTheme({
    "&.cm-focused > .cm-scroller > .cm-selectionLayer .cm-selectionBackground": {
        backgroundColor: "#FFF4",
    },
    ".cm-selectionBackground": {
        backgroundColor: "#FFF4",
    },
});

extensions.push(customSelectionStyle);
extensions.push(highlightTheme);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-codemirror",
};
</script>
<style scoped>
.codebox {
}
.selected {
    background-color: rgba(var(--v-theme-primary), 0.5) !important;
}
.pointer {
  	cursor: pointer;
}
</style>
