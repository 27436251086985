<template>
    <p class="ma-3 pt-2 text-h7" v-if="props.label">{{ props.label || "??" }}</p>
    <div v-for="(fx, x, i) in data.object">
        <vuetiform-component
            :bond="getBond(x)"
            :key="x + i"
            :format="fx.format"
            :modelValue="getTopValue(x)"
            @update:modelValue="(...args) => setTopValue(x, ...args)"
            :disabled="props.disabled"
            :ref="getTopRef(x)"
        />
        <div v-if="fx.format.formats" v-for="(fz, z, k) in fx.format.formats[getTopValue(x)]">
            <vuetiform-component
                :bond="getBond([x, getTopValue(x), z])"
                :key="x + i + z + k + '-' + getTopValue(x)"
                :format="fz"
                :modelValue="getSubValue(x, getTopValue(x), z)"
                @update:modelValue="(...args) => setSubValue(x, getTopValue(x), z, ...args)"
                :disabled="props.disabled"
                :ref="getSubRef(x, getTopValue(x), z)"
            />
        </div>
    </div>
</template>

<script setup>
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";

import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

// identifier and index are needed for array index identification.
const props = defineProps(["bond", "format", "modelValue", "disabled", "identifier", "index", "label"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({ object: {} });

const Đ = (msg, ...args) => {
    //Ł("×××", msg, props.format.label, JSON.stringify(...args));
};

data.object = generate(props.format, props.modelValue);

function generate(format = {}, modelValue = {}) {
    const object = {};
    for (const x in format) {
        const o = data.object[x] || {
            valid: true,
            ref: null,
            sub: {},
        };
        object[x] = Object.assign(o, {
            format: format[x],
            value: modelValue[x],
        });
        if (format[x].formats)
            for (const y in format[x].formats) {
                object[x].sub[y] = {};
                for (const z in format[x].formats[y]) {
                    let value;
                    if (modelValue[x] !== undefined) if (modelValue[x] === y) value = modelValue[z];
                    const o = data.object[x]?.sub[y][z] || { valid: true, ref: null };
                    object[x].sub[y][z] = Object.assign(o, {
                        format: format[x].formats[y],
                        value,
                    });
                }
            }
    }
    return object;
}

function getTopValue(x) {
    if (!data.object[x]) return;
    return data.object[x].value;
}

function getSubValue(x, y, z) {
    if (!data.object[x]) return;
    if (!data.object[x].sub[y]) return;
    if (!data.object[x].sub[y][z]) return;
    return data.object[x].sub[y][z].value;
}

function setTopValue(x, value, nexus = { valid: true, change: false }) {
    Đ("setTopValue", { x, value, nexus });
    data.object[x].value = value;
    data.object[x].valid = nexus.valid || true;
    updateEmitter(null, nexus);
}

function setSubValue(x, y, z, value, nexus = { valid: true, change: false }) {
    Đ("setSubValue", { x, y, z, value, nexus });
    if (!data.object[x].sub[y]) data.object[x].sub[y] = {};
    data.object[x].sub[y][z].value = value;
    data.object[x].sub[y][z].valid = nexus.valid || true;
    updateEmitter(null, nexus);
}

function getTopRef(x) {
    return (el) => {
        data.object[x].ref = el;
    };
}

function getSubRef(x, y, z) {
    return (el) => {
        data.object[x].sub[y][z].ref = el;
    };
}
function getBond(index) {
    if (!props.bond) return;
    const bond = props.bond || {};
    if (bond.stack && props.identifier) {
        const stack = Array.from(bond.stack);
        stack.push({ [props.identifier]: index });
        return { ...bond, stack };
    }
    return bond;
}

function getObject(key = "value") {
    let returns = {};
    for (const x in data.object) {
        returns[x] = data.object[x][key];
        for (const y in data.object[x].sub) if (getTopValue(x) === y) for (const z in data.object[x].sub[y]) returns[z] = data.object[x].sub[y][z][key];
    }
    return returns;
}

function getValue() {
    return getObject("value");
}

function isValid() {
    const valids = getObject("valid");
    for (const i in valids) if (valids[i] !== true) return valids[i];
    return true;
}

async function refreshComponents() {
    await nextTick();
    const refs = getObject("ref");
    Đ("refreshComponents", { refs });

    for (const r in refs)
        if (refs[r])
            if (refs[r].refresh) {
                const res = await refs[r].refresh();
                Đ("refreshComponent ...", { r, res });
            }
}

function updateEmitter(value = null, nexus = { valid: true, change: false }) {
    const _value = getValue();
    const valid = isValid();
    emit("update:modelValue", getValue(), { valid: isValid(), change: nexus.change });
}

function refresh() {
    data.object = generate(props.format, props.modelValue);
    refreshComponents();
}

defineExpose({ refresh });

onMounted(async () => {
    //updateEmitter();
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-poliform",
};
</script>

<!--script backup>

  _______________________________________________________________________
  	a poliformnak az object-en kellene átszaladnia
  	keys: {{ Object.keys(data.object || {})}}
    <div v-for="(fx, x, i) in props.format">
        <vuetiform-component
            :bond="getBond(x)"
            :key="x + i"
            :format="fx"
            :modelValue="getTopValue(x)"
            @update:modelValue="(...args) => setTopValue(x, ...args)"
            :disabled="props.disabled"
            :ref="getTopRef(x)"
        />
        <div v-if="fx.formats" v-for="(fz, z, k) in fx.formats[getTopValue(x)]">
            <vuetiform-component
                :bond="getBond([x, getTopValue(x), z])"
                :key="x + i + z + k + '-' + getTopValue(x)"
                :format="fz"
                :modelValue="getSubValue(x, getTopValue(x), z)"
                @update:modelValue="(...args) => setSubValue(x, getTopValue(x), z, ...args)"
                :disabled="props.disabled"
                :ref="getSubRef(x, getTopValue(x), z)"
            />
        </div>
    </div>

/*
async function refreshComponents() {
    await nextTick();
    const format = props.format;
    for (const x in format) {
        const r = data.object[x].ref;
        if (r) if (r.refresh) r.refresh();

        if (format[x].formats)
            for (const y in format[x].formats) {
                if (format[x].formats[y])
                    for (const z in format[x].formats[y]) {
                        const r = data.object[x].sub[y][z].ref;
                        if (r) if (r.refresh) r.refresh();
                    }
            }
    }
}
*/

/*
function getValue() {
    const format = props.format;
    let value = {};
    for (const x in format) {
        if (data.object[x] === undefined) continue; // return
        value[x] = data.object[x].value;
        if (format[x].formats)
            for (const y in format[x].formats) {
                if (format[x].formats[y])
                    if (getTopValue(x) === y)
                        for (const z in format[x].formats[y]) {
                            value[z] = data.object[x].sub[y][z].value;
                        }
            }
    }
    return value;
}


function isValid() {
    const format = props.format;
    for (const x in format) {
        if (data.object[x] === undefined) return true;
        if (data.object[x].valid !== true) return data.object[x].valid;
        if (format[x].formats)
            for (const y in format[x].formats) {
                if (format[x].formats[y])
                    if (getTopValue(x) == y)
                        for (const z in format[x].formats[y]) {
                            if (data.object[x].sub[y][z].valid !== true) return data.object[x].sub[y][z].valid;
                        }
            }
    }

    return true;
}
*/


</script-->
