const Database = {};

Database.document = (id, version) => {
    return new Promise((resolve, reject) => {
        $socket.emit("functionals:Database:document", { id, version }, ({ document, error }) => {
            if (error) console.error("%c" + error, "color: red;");
            resolve(document || {});
        });
    });
};

Database.query = (id, page, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        $socket.emit("functionals:Database:query", { id, page, itemsPerPage }, ({ documents, error }) => {
          	Ł(documents);
          	if (error) console.error("%c" + error, "color: red;");
            resolve(documents || []);
        });
    });
};

export default Database;
